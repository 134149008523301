@font-face {
  font-family: 'Georgia-regular';
  src: local('georgia-regular'),
    url(./assets/fonts/georgia-regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: "montserrat";
  src: local("montserrat"), url(./assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-display: swap;
}
