@supports (-webkit-touch-callout: none) {
  .input-field {
    width: 57px;
    height: 113px;
    margin: 0 4px;
    font-size: 42px;
  }
}

body {
  background-color: #1d1d1d;
  background-image: url("./assets/images/badge.png");
  background-repeat: no-repeat;
  background-position: top center;
  text-align: center;
  margin: 0;
  /* height: 100vh; */
  height: 100%;
  width: 100%;
}

.main-image {
  position: absolute;
  height: 0;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  z-index: -10;
}

.logo {
  width: 250px;
  height: 52px;
  margin-top: 10px;
}

.logo-holder {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.dashboard-img {
  opacity: 80%;
  filter: grayscale(60%);
  /* height: 50vh; */
  margin-bottom: 50px;
  max-width: 350px;
  cursor: pointer;
}

.dashboard-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100vw;
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 40px;
  z-index: 10;
}

.dash-buttons {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  border: 3px solid rgba(176, 147, 91, 0.8);
  border-radius: 36px;
  font-size: 14px;
  font-family: "montserrat";
  font-weight: 700;
  margin-bottom: 48px;
}

.main-title {
  margin: 0;
  color: rgb(211, 198, 125);
  text-align: center;
}

.station-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100vh;
}

.station-number {
  font-size: 15px;
  color: white;
  height: 77vh;
}
.error-inner-texts{
  height: unset!important;
}
.error-inner-texts .mintpage-button{
  position: fixed;
}

.success-mint {
  font-size: 32px;
  margin-top: 40px;
  color: white;
  margin-bottom: 0px;
  font-family: "Georgia-regular";
}

.error-div {
  margin-top: 160px;
}

.error-mint {
  font-size: 32px;
  color: #ff3333;
  margin-bottom: 0px;
  font-family: "Georgia-regular";
}

.mint-message {
  font-size: 20px;
  color: rgba(176, 147, 91, 0.8);
  margin-top: 2px;
  font-family: "Georgia-regular";
}

.navback-icon {
  position: absolute;
  left: 5px;
  top: 5px;
  padding: 40px;
}

.pin-form {
  margin-top: 100px;
}

.pin-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.enter-pin {
  margin-bottom: 30px;
  color: #cbac6e;
  font-size: 28px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.inputs {
  max-width: 276px;
  /* height: 115px;
max-height: 115px; */
  display: flex;
}
.inputs div {
  margin: 0 5px;
}
.input-field {
  margin: 0 4px;
  padding: 0;
  width: 100%;
  height: 113px;
  font-size: 42px;
  border: none;
  background-color: rgba(203, 172, 110, 0.5);
  color: #faf7f7;
  text-align: center;
  font-family: Georgia, "Times New Roman", Times, serif;
  border-radius: 0%;
  box-shadow: none;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  text-overflow: ellipsis;
}

.input-field:focus {
  outline: none;
}

.incorrect-pin {
  background-color: rgba(255, 51, 51, 0.5);
}

.warning-text {
  text-align: center;
  margin-top: 36px;
  color: #ff3333;
  font-size: 26px;
}

.navigationBUtton {
  text-align: center;
  margin-top: 30px;
}

.video-element {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.scanner {
  min-height: 100vh;
  height: 100%;
}

.qr-close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.qr-close-btn img {
  max-width: 50px;
}

.qr-close-btn:hover {
  cursor: pointer;
}

.scan-region-highlight-svg {
  stroke: #fff !important;
}

.mint-feedback-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mint-feedback-inner-texts{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 77vh;
}

.mintpage-button-holder {
  display: flex;
  justify-content: center;
  gap: 20px;
  /* margin-bottom: 40px; */
  z-index: 10;
}

.mintpage-button {
  cursor: pointer;
  border-radius: 28px;
  border: 3px solid rgba(176, 147, 91, 0.8);
  background-color: transparent;
  color: white;
  font-size: 14px;
  font-family: "montserrat";
  margin-bottom: 48px;
}

.experience {
  font-size: 22px;
  line-height: 32px;
  text-shadow: 1px 1px #000000;
  margin-top: 40px;
  margin-bottom: 1px;
}
.logout-btn{
  position: absolute;
  right: 25px;
  top: 35px;
  color: #fff;
  cursor: pointer;
}
.experience-name {
  color: #cbac6e;
  font-size: 26px;
  line-height: 36px;
  text-shadow: 1px 1px #000000;
  margin-top: 0;
  margin-bottom: 24px;
}

.splash-screen {
  min-height: 100vh;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.splash-screen-svgs {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 15px;
  margin: 0 auto;
  width: 150px;
  margin-bottom: 50px;
}

.scanner-holder {
  width: 100vw !important;
  height: 100vh !important;
}

.scanner-holder .qr-reader section {
  position: static !important;
  padding: 0 !important;
}

.scanner-holder .qr-reader section div {
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  border: none !important;
  width: 400px !important;
  height: 400px !important;
}

.scanner-holder .qr-reader section div:first-of-type {
  box-shadow: #fff 0px 0px 0px 5px inset !important;
}

.scanner-holder .qr-reader section video {
  min-height: 100vh !important;
  height: 100% !important;
}

.qr-close-btn {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 10px;
  right: 10px;
}

.qr-close-btn img {
  max-width: 50px;
}

.qr-close-btn:hover {
  cursor: pointer;
}

.scan-region-highlight-svg {
  stroke: #fff !important;
}

/* ///////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////// */
/* ///////////////////////////////////////////////////////////////////////////////// */

.splash-house {
  width: 125px;
}

.splash-logo {
  width: 150px;
}

@media (max-width: 800px) {
  .logo {
    width: 173px;
    height: 36px;
  }

  .dashboard-img {
    width: 258px;
    margin-bottom: 41px;
  }

  .buttons {
    gap: 16px;
  }

  .dash-buttons {
    border-radius: 26px;
    font-size: 12px;
    width: 126px;
    height: 50px;
    margin-bottom: 48px;
  }

  .mint-message {
    font-size: 20px;
    margin-top: 8px;
    font-family: "Georgia-regular";
    margin: 0 20px;
    margin-bottom: 24px;
  }

  .mintpage-button {
    font-size: 12px;
    font-weight: 400;
    width: 126px;
    height: 50px;
  }

  .enter-pin {
    margin-bottom: 16px;
    font-size: 20px;
  }

  .warning-text {
    margin-top: 16px;
    font-size: 20px;
  }

  .navigationBUtton {
    text-align: center;
    margin-top: 30px;
  }

  .video-element {
    height: 100%;
    width: 100%;
    margin: 0 auto;
  }

  .scanner-holder .qr-reader section div {
    width: 200px !important;
    height: 200px !important;
  }

  .scanner-holder .qr-reader section {
    position: static !important;
    padding: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }

  .experience {
    font-size: 22px;
    line-height: 25px;
  }

  .experience-name {
    font-size: 20px;
    line-height: 22px;
  }
}
